<template>
  <div
      class="border-b-2 border-primary bg-white px-6 py-2 flex justify-between"
  >
    <div class="flex items-center">
      <button
          v-if="backTo"
          @click="backPressed"
          class="p-2 mr-2 rounded text-secondary border-transparent hover:bg-orange-300 transition-colors duration-150 ease-in-out"
      >
        <svg
            class="h-6 w-h-6 stroke-current"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M19 12H5M12 19l-7-7 7-7"/>
        </svg>
      </button>
      <h1 class="text-secondary text-3xl">{{ title }}</h1>
      <span v-if="info" class="ml-4 text-2xl text-orange-500">{{ info }}</span>
      <span v-if="lastSavedTime" class="ml-4 flex items-center text-sm text-green-500">
    <svg class="h-6 w-6 fill-current mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
    Last saved: {{ lastSavedTime }}
  </span>
    </div>
    <div class="flex items-center">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    backTo: String,
    enableLogicalBack: Boolean,
    info: String,
    lastSavedTime: String,
  },
  methods: {
    backPressed: function () {
      if (this.enableLogicalBack) {
        this.$router.back();
      } else {
        this.$router.replace({name: this.backTo});
      }
    },
  },
};
</script>
