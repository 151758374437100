<template>
  <form
    @submit.prevent="saveStaff"
    autocomplete="off"
    class="w-full max-h-screen bg-background flex flex-col relative"
    :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
      v-show="isBusy"
      class="
        absolute
        inset-0
        bg-faint-white
        flex
        items-center
        justify-center
        z-10
      "
    >
      <spinner :color="'text-black'" :size="10" />
    </div>

    <page-header v-if="isEdit" :title="pageTitle">
      <button
        type="button"
        class="
          ml-4
          flex
          items-center
          bg-red-200
          border
          hover:bg-red-300
          border-red-400
          hover:border-red-500
          hover:shadow
          active:shadow-inner
          transition
          duration-300
          text-secondary
          rounded-md
          px-2
          py-1
        "
        @click="cancelEdit"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
        <span>Cancel</span>
      </button>
      <div style="padding-right: 5px"></div>
      <is-authorized :permissions="['manage:staff', 'create:staff']">
        <button
          type="submit"
          class="
          flex
          items-center
          bg-blue-200
          border
          hover:bg-blue-300
          border-blue-400
          hover:border-blue-500
          hover:shadow
          active:shadow-inner
          transition
          duration-300
          text-secondary
          rounded-md
          px-2
          py-1
        "
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save</span>
        </button>
      </is-authorized>
    </page-header>

    <page-header v-else-if="!isEdit" :title="pageTitle" backTo="staff">
      <is-authorized :permissions="['manage:staff', 'create:staff']">
        <button
          type="submit"
          class="
          flex
          items-center
          bg-blue-200
          border
          hover:bg-blue-300
          border-blue-400
          hover:border-blue-500
          hover:shadow
          active:shadow-inner
          transition
          duration-300
          text-secondary
          rounded-md
          px-2
          py-1
        "
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save</span>
        </button>
      </is-authorized>
    </page-header>

    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <!-- Stock Details -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Staff Information
            </h3>
            <hr class="flex-1 border-primary ml-4" />
          </div>
          <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">First Name<span class="text-red-400">*</span></label>
              <input v-model="staff.first_name" class="bge-input bge-input-spacing rounded" type="text" />
            </div>
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Middle Name</label>
              <input v-model="staff.middle_name" class="bge-input bge-input-spacing rounded" name="rack" type="text" />
            </div>
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Last Name<span class="text-red-400">*</span></label>
              <input v-model="staff.last_name" class="bge-input bge-input-spacing rounded" name="bin" type="text" />
            </div>
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Job Title<span class="text-red-400">*</span></label>
              <input v-model="staff.job_title" class="bge-input bge-input-spacing rounded" type="text" />
            </div>
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Work Email<span class="text-red-400">*</span></label>
              <input v-model="staff.email" class="bge-input bge-input-spacing rounded" type="text" />
            </div>
            <div class="flex flex-col row-span-2">
              <label class="uppercase font-semibold text-sm">Department<span class="text-red-400">*</span></label>
              <div class="inline-block relative h-full">
                <select v-model="staff.department" class="bge-input bge-select rounded h-full" multiple>
                  <template v-for="(department, i) in departments">
                    <option :key="i" :value="department">{{ department }} </option>
                  </template>
                </select>
              </div>
            </div>
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Telephone</label>
              <input v-model="staff.telephone" class="bge-input bge-input-spacing rounded" type="text" />
            </div>
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Mobile<span class="text-red-400">*</span></label>
              <input v-model="staff.mobile" class="bge-input bge-input-spacing rounded" type="text" />
            </div>
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Personal Email<span class="text-red-400">*</span></label>
              <input v-model="staff.personal_email" class="bge-input bge-input-spacing rounded" type="text" />
            </div>
          </div>
        </div>
      </div>

      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Staff Information
            </h3>
            <hr class="flex-1 border-primary ml-4" />
          </div>
          <div class="flex flex-wrap -my-3 -mx-5">
            <div class="w-full flex flex-col mt-3">
              <div class="flex flex-wrap">
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Postcode<span class="text-red-400">*</span></label>
                  <div class="flex w-full relative">
                    <input class="bge-input bge-input-spacing rounded flex-1" type="text" v-model="staff.postcode" />
                    <button
                      type="button"
                      @click="findAddress"
                      class="relative overflow-hidden ml-5 flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-3 py-1"
                    >
                      <svg
                        class="stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
                        <circle cx="12" cy="10" r="3" />
                      </svg>
                      <span>Find Address</span>
                      <div v-if="selectAddressState == 'Loading'" class="z-10 bg-green-200 absolute inset-0 flex items-center justify-center">
                        <spinner :color="'text-secondary'" :size="5" />
                      </div>
                    </button>
                    <!-- <button
                      type="button"
                      @click="findAddress"
                      class="relative overflow-hidden ml-5 flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-3 py-1"
                    >
                      <svg
                        class="stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
                        <circle cx="12" cy="10" r="3" />
                      </svg>
                      <span>Find Address</span>
                      <div v-if="selectAddressState == 'Loading'" class="z-10 bg-green-200 absolute inset-0 flex items-center justify-center">
                        <spinner :color="'text-secondary'" :size="5" />
                      </div>
                    </button> -->
                    <div v-if="selectAddressState == 'Results'" class="mt-5 pl-5 absolute bg-white shadow-2xl rounded-lg z-20 p-4 flex flex-col">
                      <label class="uppercase font-semibold text-sm">Select Address</label>
                      <div class="inline-block relative">
                        <select v-model="selectedAddress" class="bge-input bge-select rounded">
                          <template v-for="(address, i) in availableAddressData.addresses">
                            <option :key="i" :value="address">{{ address.formatted_address }}</option>
                          </template>
                        </select>
                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      <div class="text-right">
                        <button
                          @click="selectAddress"
                          type="button"
                          class="mt-3 inline-flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-3 py-1"
                        >
                          <svg
                            class="stroke-current h-5 w-5 mr-2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                          >
                            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
                            <circle cx="12" cy="10" r="3" />
                          </svg>
                          <span>Select Address</span>
                        </button>
                      </div>
                    </div>
                    <div class="fixed inset-0 z-10" @click="selectAddressState = 'Idle'" v-if="selectAddressState == 'Results'"></div>
                  </div>
                </div>
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Address 1<span class="text-red-400">*</span></label>
                  <input class="bge-input bge-input-spacing rounded" type="text" v-model="staff.address1" />
                </div>
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Address 2</label>
                  <input class="bge-input bge-input-spacing rounded" type="text" v-model="staff.address2" />
                </div>
              </div>
            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm">Address 3</label>
              <input class="bge-input bge-input-spacing rounded" type="text" v-model="staff.address3" />
            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm">City<span class="text-red-400">*</span></label>
              <input class="bge-input bge-input-spacing rounded" type="text" v-model="staff.city" />
            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm">County</label>
              <input class="bge-input bge-input-spacing rounded" type="text" v-model="staff.county" />
            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm">Country<span class="text-red-400">*</span></label>
              <div class="inline-block relative">
                <select v-model="staff.country" class="bge-input bge-select rounded">
                  <optgroup label="Frequently Used">
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Kenya">Kenya</option>
                    <option value="England">England</option>
                    <option value="Scotland">Scotland</option>
                    <option value="Wales">Wales</option>
                    <option value="Northern Ireland">Northern Ireland</option>
                    <option value="Republic of Ireland">Republic of Ireland</option>
                  </optgroup>
                  <optgroup label="All Countries">
                    <template v-for="(country, i) in countries">
                      <option :key="i" :value="country">{{ country }}</option>
                    </template>
                  </optgroup>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </form>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader.vue";
import Notification from "@/components/Notification.vue";
import Spinner from "@/components/Spinner.vue";
const Datepicker = () => import("vuejs-datepicker");
import { COUNTRIES } from "@/utils/constants";
import { DEPARTMENTS } from "@/utils/constants";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "CreateStaff",
  props: {
    staffId: String,
  },
  components: {
    PageHeader,
    Spinner,
    Datepicker,
    Notification,
    IsAuthorized,
  },
  data() {
    return {
      isBusy: false,
      pageTitle: "Create Staff",
      selectAddressState: "Idle",
      isEdit: false,
      staff: {},
      countries: [],
      departments: [],
    };
  },

  async mounted() {
    this.countries = COUNTRIES;
    this.departments = DEPARTMENTS;
    this.$refs.tbidentification.focus();
  },
  methods: {
    formatUnixFn: (v) => {
      return v == "" ? "" : this.$moment(v).unix();
    },
    findAddress: async function() {
      try {
        if (this.staff.postcode) {
          this.selectAddressState = "Loading";
          let result = await this.SalesProspectService.findAddressFromPostcode(this.staff.postcode);
          this.availableAddressData = result.data;
          this.selectAddressState = "Results";
        }
      } catch (error) {
        console.error(error);
        this.selectAddressState = "Idle";
        let errorText;
        switch (error.status) {
          case 404:
            errorText = "No addresses could be found for this postcode.";
            break;
          case 400:
            errorText = "Postcode is invalid";
            break;
          case 429:
            errorText = "You have made more requests than your allowed limit.";
            break;
          default:
            errorText = "There has been an error.";
            break;
        }
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: `${error.status}: ${error.data}`,
                  close: onClose,
                },
              },
              errorText
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    selectAddress: function() {
      if (this.selectedAddress) {
        this.selectAddressState = "Idle";
        this.staff.postcode = this.availableAddressData.postcode;
        this.staff.address1 = this.selectedAddress.line_1;
        this.staff.address2 = this.selectedAddress.line_2;
        this.staff.address3 = this.selectedAddress.line_3;
        this.staff.city = this.selectedAddress.town_or_city;
        this.staff.county = this.selectedAddress.county;
        this.staff.country = this.selectedAddress.country;
      }
    },
    saveStaff: async function() {
      let msg;
      let isAdd = false;
      if (!this.staff.first_name) {
        msg = "Please enter first name.";
      } else if (!this.staff.last_name) {
        msg = "Please enter last name.";
      } else if (!this.staff.job_title) {
        msg = "Please enter job title.";
      } else if (!this.staff.department) {
        msg = "Please enter department.";
      } else if (!this.staff.email) {
        msg = "Please enter email.";
      } else if (!this.$validator.isEmail(this.staff.email)) {
        msg = "Please enter valid email.";
      } else if (!this.staff.mobile) {
        msg = "Please enter mobile.";
      } else if (!this.$validator.isMobilePhone(this.staff.mobile, "en-GB")) {
        msg = "Please enter valid mobile, ex. 074XXXXXXXX";
      } else if (!this.staff.personal_email) {
        msg = "Please enter personal email.";
      } else if (!this.$validator.isEmail(this.staff.personal_email)) {
        msg = "Please enter valid personal email.";
      } else if (!this.staff.postcode) {
        msg = "Please enter postcode.";
      } else if (!this.$validator.isPostalCode(this.staff.postcode, "GB")) {
        msg = "Please enter valid postcode.";
      } else if (!this.staff.address1) {
        msg = "Please enter address1.";
      } else if (!this.staff.city) {
        msg = "Please enter city.";
      } else if (!this.staff.country) {
        msg = "Please enter country.";
      }

      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.isBusy = true;

      try {
        let createResult = await this.StaffService.createStaff(this.staff);
        this.staff.staff_id = createResult.data.staff_id;
        this.$store.dispatch("setLastSelectedStaff", this.staff.staff_id);
        this.$router.push({
          name: "staff-edit",
          params: {
            staffId: this.staff.staff_id,
            isCreateResult: true,
          },
        });
      } catch (error) {
        if (error.status == 409) {
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "warning",
                    title: "Duplicate Staff",
                    close: onClose,
                  },
                },
                "A Staff already exists with that identification number"
              );
            },
            {
              position: "top-right",
            }
          );
        } else {
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: `Error: ${error.status}`,
                    close: onClose,
                  },
                },
                "Couldn't create Staff"
              );
            },
            {
              position: "top-right",
            }
          );
          console.error(error);
        }
      }

      this.isBusy = false;
    },

    cancelEdit: function() {
      this.$router.push({
        name: "staff-edit",
        params: { staffId: this.staff.staff_Id },
      });
    },
  },
};
</script>
